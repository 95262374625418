import React from "react";
import favicon from "../../assets/images/favicon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const InfoSection = () => {
  return (
    <section className="info_section">
      <div className="container">
        <div className="info_top">
          <div className="row">
            <div className="col-md-7 col-lg-6">
              <div className="info_detail">
                <a href="/">
                  <img src={favicon} alt="favicon" />
                  <h4>Predexyo</h4>
                </a>
                <p>Crypto-specific tools for blockchain institutions</p>
                <div className="social_box">
                  <a href="https://facebook.com">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href="https://twitter.com">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href="https://linkedin.com">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a href="https://youtube.com">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info_form">
                <h4>SIGN UP TO OUR NEWSLETTER</h4>
                <form action="">
                  <input type="email" placeholder="Enter Your Email" />
                  <button type="submit">
                    Subscribe <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
