import React from "react";
import hero_bg from "../../assets/images/hero-bg.jpg";
import Contact from "../../components/layout/Contact";
import InfoSection from "../Home/Infosection";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";

const ContactPage = () => {
  return (
    <div>
      <div className="hero_area" style={{ minHeight: "auto" }}>
        <div className="hero_bg_box">
          <img src={hero_bg} alt="background" />
        </div>
        <Header />
      </div>
      <Contact />
      <InfoSection />
      <Footer />
    </div>
  );
};

export default ContactPage;
