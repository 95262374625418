import React from "react";
import hero_bg from "../../assets/images/hero-bg.jpg";
import Slider from "./Slider";
import ServiceSection from "./ServiceSection";
import Team from "./TeamSection";
import Contact from "../../components/layout/Contact";
import InfoSection from "./Infosection";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";

function Home() {
  return (
    <>
      <div className="hero_area">
        <div className="hero_bg_box">
          <img src={hero_bg} alt="background" />
        </div>
        <Header />
        <Slider />
      </div>

      <ServiceSection />
      <Team />
      <Contact />
      <InfoSection />
      <Footer />
    </>
  );
}

export default Home;
