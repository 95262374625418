import React from "react";
import hero_bg from "../../assets/images/hero-bg.jpg";
import InfoSection from "../Home/Infosection";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Team from "../Home/TeamSection";

const About = () => {
  return (
    <>
      <div className="hero_area" style={{ minHeight: "auto" }}>
        <div className="hero_bg_box">
          <img src={hero_bg} alt="background" />
        </div>
        <Header />
      </div>
      <Team />
      <InfoSection />
      <Footer />
    </>
  );
};

export default About;
