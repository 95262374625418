import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Slider() {
  return (
    <section className="slider_section">
      <div id="customCarousel1" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-lg-7">
                  <div className="detail-box">
                    <h1>
                      A software development company building <br />
                      products for the Doefin protocol
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-lg-7">
                  <div className="detail-box">
                    <h1>
                      Institutional grade <br />
                      on-chain derivatives for <br />
                      hedging, trading and investment purposes
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-lg-7">
                  <div className="detail-box">
                    <h1>
                      Addressing needs of crypto-institutions with
                      crypto-specific solutions
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="carousel_btn-box">
            <a
              className="carousel-control-prev"
              href="#customCarousel1"
              role="button"
              data-slide="prev"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#customCarousel1"
              role="button"
              data-slide="next"
            >
              <FontAwesomeIcon icon={faArrowRight} />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Slider;
