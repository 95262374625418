import React from "react";

function Contact() {
  function sendMail() {
    const form = document.getElementById("contact_form");

    // emailjs.sendForm("service_t7f2fvu", "template_vv421ey", form).then(
    //   function (response) {
    //     console.log("SUCCESS!", response.status, response.text);
    //     alert("Email sent successfully!");
    //   },
    //   function (error) {
    //     console.log("FAILED...", error);
    //     alert("Failed to send the email. Please try again.");
    //   }
    // );
  }
  return (
    <section className="contact_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-5 mx-auto">
            <div className="form_container mb_mobile_45 pr-xl-4">
              <div className="heading_container heading_center">
                <h2>Get In Touch</h2>
              </div>
              <form id="contact_form" action="#" className="contact_form">
                <input
                  type="text"
                  name="your_name"
                  className="form-control"
                  id="inputName"
                  placeholder="Your Name"
                />
                <input
                  type="text"
                  name="phone_number"
                  className="form-control"
                  id="inputNumber"
                  placeholder="Mobile Number"
                />
                <input
                  type="email"
                  name="email_address"
                  className="form-control"
                  id="inputMail"
                  placeholder="Email"
                />
                <input
                  type="text"
                  name="your_message"
                  className="form-control"
                  id="inputMessage"
                  placeholder="Message"
                />
                <div className="btn-box btn-center">
                  <button
                    type="submit"
                    className="submit_btn"
                    onClick={() => sendMail()}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
