import React from "react";
import "./LoginSection.css"; // Import your custom CSS file for styling
import { Link } from "react-router-dom";

const LoginSection = () => {
  return (
    <section className="contact_section">
      <div
        className="container-fluid"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div className="col-md-4 text-center">
          <h1>User Login</h1>
          <div className="pt-3">
            <form id="login_form" action="#" className="login_form">
              <input
                type="text"
                name="your_name"
                className="form-control"
                id="inputName"
                placeholder="Your Name"
              />
              <input
                type="password"
                name="user_password"
                className="form-control"
                id="user_password"
                placeholder="Password"
              />
              <div className="btn-box btn-center">
                <Link to="/home" type="submit" className="submit_btn">
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginSection;
