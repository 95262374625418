import React from "react";

function ServiceSection() {
  return (
    <section className="service_section layout_padding">
      <div className="service_container">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our Services</h2>
            <p></p>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="box">
                <div className="detail-box1">
                  <h5>DECENTRALISED</h5>
                  <p>P2P trading & Self Custody</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box">
                <div className="detail-box1">
                  <h5>CAPITAL EFFICIENT</h5>
                  <p>No prefunding costs & onchain margining</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="box">
                <div className="detail-box1">
                  <h5>RISK MANAGEMENT SOLUTIONS</h5>
                  <p>Crypto-specific hedging tools</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
